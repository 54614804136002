import _ from 'lodash'
import * as ActionTypes from '../actions'
import merge from 'lodash/merge'
import paginate from './paginate'
import postReducer from './postReducer'
import { combineReducers } from 'redux'

// Updates an entity cache in response to any action with response.entities.
const entities = (state = { user: null, item: null, product: null }, action) => {
  if (action.response && action.response.entities) {
    let newState =  merge({}, state, action.response.entities)
    return postReducer(newState, action)
  }

  // Need also call postReducer for REQUEST to add insertObject
  if (action.type.endsWith('REQUEST'))
    return postReducer(state, action)

  // Clear siteMessage if needed
  if (action.type === ActionTypes.RESET_SITE_MESSAGE) {
    let newState = merge({}, state)
    if(newState['siteMessage']) delete newState['siteMessage']
    return postReducer(newState, action)
  }

  return state
}

// Updates the pagination data for different actions.
const pagination = combineReducers({
  items: paginate({
    mapActionToKey: action => action.fullName,
    types: [
      ActionTypes.ITEMS_REQUEST,
      ActionTypes.ITEMS_SUCCESS,
      ActionTypes.ITEMS_FAILURE
    ]
  }),
  products: paginate({
    mapActionToKey: action => action.fullName,
    types: [
      ActionTypes.PRODUCTS_BYITEM_REQUEST,
      ActionTypes.PRODUCTS_BYITEM_SUCCESS,
      ActionTypes.PRODUCTS_BYITEM_FAILURE
    ]
  })
})

const rootReducer = combineReducers({
  entities,
  pagination
})

export default rootReducer
