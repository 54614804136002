import { normalize, schema } from 'normalizr'
import { camelizeKeys } from 'humps'

const API_ROOT = 'http://localhost:5000/' // TODO env based : .alissastea.com/'

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint, schema, method, requestOptions) => {
  //console.log('METHOD in call'. method);
  const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint

  return fetch(fullUrl, requestOptions)
    .then(response =>
      response.json().then(json => {
        if (!response.ok) {
          return Promise.reject(json)
        }

        /* ERROR looks like this on POST
          entitites
            post
              undefined
                success:false
                result:null
                message
                  code:"ER_BAD_NULL_ERROR"
                  errno:1048
                  sqlMessage:"Column 'product_id' cannot be null"
                  sqlState:"23000"
                  index:0
                  sql:"insert into cart_product (cart_id,product_id,quantity,price) values (1, NULL, 1, NULL)"
        */
        //console.log('json', json);
        //console.log('total, data', json.total, json.data);
        const camelizedJson = json.data ? camelizeKeys(json.data) : camelizeKeys(json)
        const nextPageUrl = json.nextPageUrl //getNextPageUrl(response)
        //console.log('normalize with schema', normalize(camelizedJson, schema));
        //console.log('schema', schema);
        /*console.log('callApi return json', Object.assign({},
          normalize(camelizedJson, schema),
          { nextPageUrl }
        ));*/

        return Object.assign({nextPageUrl},
          normalize(camelizedJson, schema)
        )
      })
    )
}

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

// GitHub's API may return results with uppercase letters while the query
// doesn't contain any. For example, "someuser" could result in "SomeUser"
// leading to a frozen UI as it wouldn't find "someuser" in the entities.
// That's why we're forcing lower cases down there.

const userSchema = new schema.Entity('user', {}, {
  idAttribute: user => user.user_id
})

// item VS items is wierd
const itemSchema = new schema.Entity('item', {}, {
  idAttribute: item => item.itemId
})

const itemsSchema = new schema.Entity('items', {
}, {
  idAttribute: item => item.itemId
})

const productsSchema = new schema.Entity('products', {
}, {
  idAttribute: product => product.productId
})

const productSchema = new schema.Entity('product', {
  item: itemSchema
}, {
  idAttribute: product => product.productId
})

const cartProductSchema = new schema.Entity('cartProduct', {
  product: productSchema
}, {
  idAttribute: cartProduct => cartProduct.cartProductId
})
const cartSchema = new schema.Entity('cart', {
  cartProducts: [cartProductSchema]
}, {
  idAttribute: cart => cart.cartId
})

const postSchema = new schema.Entity('post', {}, {})


// Schemas for Github API responses.
// TODO ADD Items and other schema from just above here with 2 values like USER
export const Schemas = {
  CART: cartSchema,
  USER: userSchema,
  USER_ARRAY: [userSchema],
  ITEM: itemSchema,
  ITEMS: [itemSchema],
  POST: postSchema,
  PRODUCTS: [productSchema]
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API'

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
const api = store => next => action => {
  const callAPI = action[CALL_API]
  //console.log('WTF callAPi', callAPI);
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let { endpoint, method } = callAPI
  //console.log('WTF enpoint from callAPI action in api', endpoint);
  const { schema, types, body, model } = callAPI

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState())
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }
  if (!schema) {
    throw new Error('Specify one of the exported Schemas.')
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }
  let requestOptions = {}
  if (!method) {
    method = 'GET'
  }
  switch (method) {
    case 'DELETE':
      requestOptions = {
        method: 'DELETE',
      };
      break;
    case 'POST':
      requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      };
      break;
    case 'GET':
    default:
      requestOptions = {
        method: 'GET'
      };
      break;
  }
  //console.log('requestOptions', requestOptions);

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  const [ requestType, successType, failureType ] = types
  next(actionWith({ type: requestType, requestModel: {body, model} }))

  //console.log('WTF calling callApi with', endpoint);
  return callApi(endpoint, schema, method, requestOptions).then(
    response => next(actionWith({
      response,
      type: successType
    })),
    error => next(actionWith({
      type: failureType,
      error: error.message || 'Something bad happened'
    }))
  )
}
export default api;
