import { CALL_API, Schemas } from '../middleware/api'

////////////////////////////////////////////////////////////////////////////
// User
////////////////////////////////////////////////////////////////////////////
export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILURE = 'USER_FAILURE'

const fetchUser = user_id => ({
  [CALL_API]: {
    types: [ USER_REQUEST, USER_SUCCESS, USER_FAILURE ],
    endpoint: `users/${user_id}`,
    schema: Schemas.USER
  }
})

export const loadUser = (user_id) => (dispatch, getState) => {
  const user = getState().entities.user;
  if (user) {
    return user;
  }

  return dispatch(fetchUser(user_id))
}

////////////////////////////////////////////////////////////////////////////
// Item
////////////////////////////////////////////////////////////////////////////
export const ITEM_REQUEST = 'ITEM_REQUEST'
export const ITEM_SUCCESS = 'ITEM_SUCCESS'
export const ITEM_FAILURE = 'ITEM_FAILURE'

const fetchItem = item_id => ({
  [CALL_API]: {
    types: [ ITEM_REQUEST, ITEM_SUCCESS, ITEM_FAILURE ],
    endpoint: `items/${item_id}`,
    schema: Schemas.ITEM
  }
})

export const loadItem = (item_id) => (dispatch, getState) => {
  const item = getState().entities.item;
  if (item) {
    return item;
  }

  return dispatch(fetchItem(item_id))
}

////////////////////////////////////////////////////////////////////////////
// Items
////////////////////////////////////////////////////////////////////////////
export const ITEMS_REQUEST = 'ITEMS_REQUEST'
export const ITEMS_SUCCESS = 'ITEMS_SUCCESS'
export const ITEMS_FAILURE = 'ITEMS_FAILURE'

const fetchItems = (fullName, nextPageUrl) => ({
  fullName,
  [CALL_API]: {
    types: [ ITEMS_REQUEST, ITEMS_SUCCESS, ITEMS_FAILURE ],
    endpoint: nextPageUrl,
    schema: Schemas.ITEMS
  }
})

export const loadItems = (nextPage) => (dispatch, getState) => {
  const {
    nextPageUrl = nextPage ? nextPage : 'items',
    pageCount = 0
  } = getState().pagination.items['items'] || {}

  if (pageCount > 0 && !nextPage) {
    return null
  }

  return dispatch(fetchItems('items', nextPageUrl))
}

////////////////////////////////////////////////////////////////////////////
// Products
////////////////////////////////////////////////////////////////////////////
export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST'
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS'
export const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE'

const fetchProducts = (fullName, nextPageUrl) => ({
  fullName,
  [CALL_API]: {
    types: [ PRODUCTS_REQUEST, PRODUCTS_SUCCESS, PRODUCTS_FAILURE ],
    endpoint: nextPageUrl,
    schema: Schemas.PRODUCTS
  }
})

export const loadProducts = (nextPage) => (dispatch, getState) => {
  const {
    nextPageUrl = nextPage ? nextPage : `products`,
    pageCount = 0
  } = getState().pagination.products['products'] || {}

  if (pageCount > 0 && !nextPage) {
    return null
  }

  return dispatch(fetchProducts('products', nextPageUrl))
}
////////////////////////////////////////////////////////////////////////////
// Products By item_id
////////////////////////////////////////////////////////////////////////////
export const PRODUCTS_BYITEM_REQUEST = 'PRODUCTS_BYITEM_REQUEST'
export const PRODUCTS_BYITEM_SUCCESS = 'PRODUCTS_BYITEM_SUCCESS'
export const PRODUCTS_BYITEM_FAILURE = 'PRODUCTS_BYITEM_FAILURE'

const fetchProductsByItem = (fullName, itemId, nextPageUrl) => ({
  fullName,
  [CALL_API]: {
    types: [ PRODUCTS_BYITEM_REQUEST, PRODUCTS_BYITEM_SUCCESS, PRODUCTS_BYITEM_FAILURE ],
    endpoint: nextPageUrl,
    schema: Schemas.PRODUCTS
  }
})

export const loadProductsByItem = (itemId, nextPage) => (dispatch, getState) => {
  const {
    nextPageUrl = nextPage ? nextPage : `products/byitem/${itemId}`,
    pageCount = 0
  } = getState().pagination.items['products'] || {}

  if (pageCount > 0 && !nextPage) {
    return null
  }

  return dispatch(fetchProductsByItem('products', itemId, nextPageUrl))
}
////////////////////////////////////////////////////////////////////////////
// Shopping Cart
////////////////////////////////////////////////////////////////////////////
export const SHOPPING_CART_REQUEST = 'SHOPPING_CART_REQUEST'
export const SHOPPING_CART_SUCCESS = 'SHOPPING_CART_SUCCESS'
export const SHOPPING_CART_FAILURE = 'SHOPPING_CART_FAILURE'

const fetchShoppingCart = (fullName, sessionGuid, userId, nextPageUrl) => ({
  fullName,
  [CALL_API]: {
    types: [ SHOPPING_CART_REQUEST, SHOPPING_CART_SUCCESS, SHOPPING_CART_FAILURE ],
    endpoint: nextPageUrl,
    schema: Schemas.CART
  }
})

export const loadShoppingCart= (sessionGuid, userId, nextPage) => (dispatch, getState) => {
  const {
    nextPageUrl = `cart/1/full`, // TODO by user or session
    pageCount = 0
  } = getState().pagination.items['cart'] || {}

  if (pageCount > 0 && !nextPage) {
    return null
  }

  return dispatch(fetchShoppingCart('cart', sessionGuid, userId, nextPageUrl))
}
////////////////////////////////////////////////////////////////////////////
// Add To Cart
////////////////////////////////////////////////////////////////////////////
export const ADD_CART_REQUEST = 'ADD_CART_REQUEST' // ALSO in reducers/postReducder!!!!
export const ADD_CART_SUCCESS = 'ADD_CART_SUCCESS'
export const ADD_CART_FAILURE = 'ADD_CART_FAILURE'

const postAddToCart = (cartProduct) => ({
  [CALL_API]: {
    types: [ ADD_CART_REQUEST, ADD_CART_SUCCESS, ADD_CART_FAILURE ],
    endpoint: `cart_product`,
    method: 'POST',
    body: cartProduct,
    model: 'cartProduct',
    schema: Schemas.POST
  }
})

export const addToCart= (cartProduct) => (dispatch, getState) => {
  return dispatch(postAddToCart(cartProduct))
}
////////////////////////////////////////////////////////////////////////////
// Update Cart Product
////////////////////////////////////////////////////////////////////////////
export const UPDATE_CARTPRODUCT_REQUEST = 'UPDATE_CARTPRODUCT_REQUEST' // ALSO in reducers/postReducder!!!!
export const UPDATE_CARTPRODUCT_SUCCESS = 'UPDATE_CARTPRODUCT_SUCCESS'
export const UPDATE_CARTPRODUCT_FAILURE = 'UPDATE_CARTPRODUCT_FAILURE'

const postUpdateCartProduct = (cartProduct) => ({
  [CALL_API]: {
    types: [ UPDATE_CARTPRODUCT_REQUEST, UPDATE_CARTPRODUCT_SUCCESS, UPDATE_CARTPRODUCT_FAILURE ],
    endpoint: `cart_product/${cartProduct.cartProductId}`,
    method: 'POST',
    body: cartProduct,
    model: 'cartProduct',
    schema: Schemas.POST
  }
})

export const updateCartProduct= (cartProduct) => (dispatch, getState) => {
  return dispatch(postUpdateCartProduct(cartProduct))
}
////////////////////////////////////////////////////////////////////////////
// Delete From Cart
////////////////////////////////////////////////////////////////////////////
export const DELETE_CARTPRODUCT_REQUEST = 'DELETE_CARTPRODUCT_REQUEST' // ALSO in reducers/postReducder!!!!
export const DELETE_CARTPRODUCT_SUCCESS = 'DELETE_CARTPRODUCT_SUCCESS'
export const DELETE_CARTPRODUCT_FAILURE = 'DELETE_CARTPRODUCT_FAILURE'

const deleteCartProduct = (cartProduct) => ({
  [CALL_API]: {
    types: [ DELETE_CARTPRODUCT_REQUEST, DELETE_CARTPRODUCT_SUCCESS, DELETE_CARTPRODUCT_FAILURE ],
    endpoint: `cart_product/${cartProduct.cartProductId}`,
    method: 'DELETE',
    model: 'cartProduct',
    body: cartProduct,
    schema: Schemas.POST
  }
})

export const deleteFromCart= (cartProduct) => (dispatch, getState) => {
  return dispatch(deleteCartProduct(cartProduct))
}
////////////////////////////////////////////////////////////////////////////
// Reset Site Message
////////////////////////////////////////////////////////////////////////////
export const RESET_SITE_MESSAGE = 'RESET_SITE_MESSAGE'

// Resets the currently visible site message.
export const resetSiteMessage = () => ({
    type: RESET_SITE_MESSAGE
})
