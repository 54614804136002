import _ from 'lodash'

const POST_ACTIONS = [
  'ADD_CART_SUCCESS',
  'ADD_CART_REQUEST',
  'ADD_CART_FAILURE',
  'DELETE_CARTPRODUCT_REQUEST',
  'DELETE_CARTPRODUCT_SUCCESS',
  'DELETE_CARTPRODUCT_FAILURE'
];

// Update the entities cache on POST
const postReducer = (state = {}, action) => {

  if (!POST_ACTIONS.includes(action.type)) {
    return state
  }

  let actionType = ''
  if (action.type.endsWith('REQUEST')) actionType = 'REQUEST'
  if (action.type.endsWith('SUCCESS')) actionType = 'SUCCESS'
  if (action.type.endsWith('FAILURE')) actionType = 'FAILURE'
    
  // Update pagination by key
  if (action.type.startsWith('DELETE')) {
    switch (actionType) {
      case 'REQUEST':
        return {
          ...state,
          'deleteObject': {
            'model': action.requestModel.model,
            'body': action.requestModel.body
          }
        }
      case 'SUCCESS':
        const { deleteObject } = state
        let nextState = {...state}
        // delete the actual object from entities cache
        const { model, body } = deleteObject
        const keyName = `${model}Id`
        const id = body[keyName]

        // delete object from entities
        delete nextState[model][id]

        // clean up temp state
        if (nextState['post']) delete nextState['post']
        if (nextState['deleteObject']) delete nextState['deleteObject']
        nextState['siteMessage'] = {
          'type': 'success',
          'message': 'Item removed from Cart' // TODO look at action type
        }
        return nextState
      case 'FAILURE':
        // remove temp object
        let errorState = {...state}
        if (errorState['deleteObject']) delete errorState['deleteObject']
        errorState['siteMessage'] = {
          'type': 'error',
          'message': 'Unexpected error'
        }
        return errorState
      default:
        return state
    }
  } else if (action.type.startsWith('ADD')) {
    switch (actionType) {
      case 'REQUEST':
        return {
          ...state,
          'insertObject': {
            'model': action.requestModel.model,
            'body': action.requestModel.body
          }
        }
      case 'SUCCESS':
        // TODO this is pretty gross, better use of spread and lodash would help
        const { insertObject } = state
        let nextState = {...state}
        // push the actual object into entities cache
        const { model, body } = insertObject
        /* post response looks like this:
         entitites
          post
            undefined
              success:false
              result:null
              message
        */
        const response = action.response.entities.post['undefined'] //ugh
        if (response.success) {
          const insertId = response.result
          const keyName = `${model}Id`
          let modelData = {}
          modelData[keyName] = insertId
          _.keys(body).forEach(key =>
            modelData[_.camelCase(key)] = body[key]
          );

          // Push temp object into entities
          if(!nextState[model]) nextState[model] = {} // push cartProduct here would fail withot a cart
          nextState[model][insertId] = modelData
          nextState['siteMessage'] = {
            'type': 'success',
            'message': 'Item added to Cart' // TODO look at action type
          }
        } else {
          nextState['siteMessage'] = {
            'type': 'error',
            'message': 'Unexpected error'
          }
        }
        if (nextState['post']) delete nextState['post']
        if (nextState['insertObject']) delete nextState['insertObject']
        return nextState
      case 'FAILURE':
        // remove temp object
        let errorState = {...state}
        if (errorState['insertObject']) delete errorState['insertObject']
        // set error message
        errorState['siteMessage'] = {
          'type': 'error',
          'message': 'Unexpected error'
        }
        return errorState
      default:
        return state
    }
  }
}

export default postReducer
