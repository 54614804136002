import Image from 'react-bootstrap/Image';
import React from 'react';
import ReactDOM from 'react-dom';
import leafBig from './assets/leafBig.svg';

import './Splash.css';

class Splash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {index: 1};
  }

  componentDidMount() {
    const timer = setInterval(() => {
      this.shuffle();
    }, 3000);
  }

  shuffle = () => {
    let {index} = this.state;
    let botPic = ReactDOM.findDOMNode(this.refs['t1']); // bot
    let topPic = ReactDOM.findDOMNode(this.refs['t2']); // top
    //topPic.classList.add("fade-out");
    //topPic.classList.add("fade");
    topPic.src = `tea${index}.jpeg`;

    /*topPic.addEventListener("transitionend", function x()
    {
        topPic.removeEventListener("transitionend", x);
        topPic.classList.remove("fade-out");
        topPic.classList.remove("fade");
        let src = botPic.src;
        botPic.src = `tea${index}.jpeg`;
        topPic.src = src;
    }); */


    if (index >= 15) index = 2;
    else if (index == 6) index = 8;
    else index = index + 1;
    this.setState({index})
  }

  render() {
    return (
      <div className="splash"> 
        <div className="logo">
          <img src="/logo70.png" width="207" height="70" className="LogoNav" alt="Alissa's TEA logo" />
        </div>
        <p>
          Coming Soon...
          <img className="rotater leaf" src={leafBig} width="24" height="16" alt="Animated Leaf" />
        </p>
        <div className="imageConMask">
          <div className="imageCon">
            <Image ref="t1" className="thumbnail over1" width="200" height="200" src="tea2.jpeg" alt="Tea Splash Image" />
            <Image ref="t2" className="thumbnail over2" width="200" height="200" src="tea1.jpeg" alt="Tea Splash Image" />
          </div>
        </div>
          <div className="imageHidden">
            <Image className="thumbnail" width="200" height="200" src="tea2.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="200" height="200" src="tea3.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea4.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea5.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea6.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea8.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea9.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea10.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea11.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea12.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea13.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea14.jpeg" alt="Tea Splash Image" />
            <Image className="thumbnail" width="125" height="125" src="tea15.jpeg" alt="Tea Splash Image" />
          </div>
      </div>
    );
  }
}

export default Splash;
